import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../components/privacy-page.css'

class PrivacyPage extends React.Component {

    render() {
        return <Layout>
            <SEO title="Privacy Policy"/>
            <div className={'privacy'}>
                <div className={'content'}>
                    <h2>Privacy Policy</h2>
                    <p>This application does not collect nor store personally identifiable information about any
                        individual user. This application uses persistent storage only to remember user preferences and
                        that storage exists only on user's device and never leaves that device.
                        All other applications data is kept in runtime memory only.</p>

                    <p>When application runs, it downloads images for specific URL user
                        has selected and only after user has explicitly requested the application to do so.
                        Once the application execution ends, all data and the generated zip file are
                        lost once memory recycles. While not in use this extension does nothing.</p>

                    <p>This application may collect anonymous and aggregated user
                        data according to Google Analytics (GA) Terms of Service (TOS) to improve user experience
                        and to identify key functionality. This information does not
                        identify individual users or include any personally identifiable information.</p>


                    <h3>Minimum Permissions Disclaimer</h3>

                    <p>The following list details each required permission and justification for their use:</p>

                    <ul>
                        <li><code>activeTab</code><br/>Extension analyzes active tab to look for images to download
                        </li>

                        <li>
                            <code>http://*/*</code> &nbsp; and &nbsp; <code>https://*/*</code><br/>Actual images are hosted over various domains. This permission is needed to enable CORS to fetch image data directly on user's device in javascript. The request is direct from user's device to server hosting the image (no proxies or other servers are being used). This permission is the minimal permission needed to accomplish this core extension functionality.
                        </li>

                        <li>
                            <code>contextMenus</code><br/>Non-core extension features are accessible through the context menu
                        </li>

                        <li><code>storage</code><br/>stores user preferences.</li>
                    </ul>

                    <p>Last Modified: May 9, 2021</p>
                </div>
            </div>
        </Layout>
    };
}

export default PrivacyPage
